@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo:300,400,500,600&display=swap);
html {
  background-color: #dddee3;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

/* Dashboard */

.toolBarHome {
  position: fixed;
  top: 0;
  left: 0;
  width: 90vw;
  height: 5vw;
  display: flex;
  justify-content: space-between;
  padding: 0vw 5vw;
  align-items: center;
}
.toolBarHome > div {
  width: 15vw;
  padding: 0.3vw 0vw;
  border-radius: 2px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s;
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.281);
}
#RMtrans {
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0);
  padding: 0.3vw 1.3vw;
  font-weight: 600;
  border-bottom: 2px solid #8e8e8e;
  color: #4d4d4d;
  font-size: 1vw;
}
#RMtransGuest {
  font-size: 1vw;
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0);
  padding: 0vw 0vw;
  margin-top: 1vw;
  font-weight: 600;
  border-bottom: 2px solid #8e8e8e;
  color: #4d4d4d;
}
#RMtransGuest > span {
  font-size: 0.8vw;
  font-weight: 500;
}
.toolBarContent {
  position: absolute;
  top: -3vw;
  right: 5vw;
  background-color: rgba(68, 68, 68, 0);
  width: auto;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}
.searchSummary {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 2vw;
  background-color: #e8e9eb;
  border-radius: 3px;
  font-size: 1.1vw;
  padding: 0.5vw 0vw;
  font-weight: 600;
}
#filter {
  width: 10vw;
  height: 2vw;
  margin-right: 2vw;
  font-size: 1vw;
  background-color: rgb(97, 97, 97);
  height: 2.5vw;
  display: flex;
  color: #fff;
  justify-content: space-around;
  align-items: center;
  font-size: 1.1vw;
  border-radius: 3px;
  cursor: pointer;
}
.searchIcon {
  padding-right: 0.5vw;
}
.yearTab{
  margin-top: 15px;
  display: flex;
}
.yearTab > div{
  padding: 2px 15px;
  font-size: 13px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 4px;
  border: 2px solid #cdcdcd;
  color: #8c8c8c;
  background-color: transparent;
  cursor: pointer;
}
.yearTab > div:hover{
  opacity: 0.8;
}
.selectedYear{
  font-weight: 600 !important;
  border: 2px solid #0036d8 !important;
  color: #0036d8 !important;
}
#filter:hover {
  background-color: rgb(67, 67, 67);
  transition: 1s;
}
#totals {
  width: 34vw;
  height: 2vw;
  margin-right: 2vw;
  font-size: 0.9vw;
  background-color: rgb(255, 255, 255);
  height: 2.5vw;
  display: flex;
  color: rgb(94, 94, 94);
  justify-content: space-around;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
}
#search {
  width: 20vw;
  height: 2vw;
  margin-right: 2vw;
  background-color: rgb(99, 130, 128);
  height: 2.5vw;
  display: flex;
  color: #fff;
  justify-content: space-around;
  align-items: center;
  font-size: 1.1vw;
  border-radius: 3px;
  cursor: pointer;
}
.searchIcon {
  padding-right: 0.5vw;
}
#search:hover {
  background-color: rgb(153, 193, 208);
  transition: 1s;
}
#addCarrier {
  width: 12vw;
  height: 2vw;
  margin-right: 0vw;
  background-color: rgb(50, 77, 118);
  height: 2.5vw;
  display: flex;
  color: rgb(255, 255, 255);
  justify-content: space-around;
  align-items: center;
  font-size: 1.1vw;
  border-radius: 3px;
  cursor: pointer;
}
#filterLanes {
  width: 10vw;
  height: 2vw;
  margin-right: 2vw;
  background-color: rgb(63, 63, 63);
  height: 2.5vw;
  display: flex;
  color: rgb(255, 255, 255);
  justify-content: space-around;
  align-items: center;
  font-size: 1.1vw;
  border-radius: 3px;
  cursor: pointer;
}
#filterMC {
  width: 13vw;
  height: 2vw;
  margin-right: 2vw;
  font-size: 1vw;
  background-color: rgb(255, 255, 255);
  height: 2.5vw;
  display: flex;
  color: rgb(77, 77, 77);
  justify-content: space-around;
  align-items: center;
  font-size: 1.1vw;
  border-radius: 3px;
  cursor: pointer;
}
#filterMC > input {
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  text-indent: 5%;
}
#filterDispatcher {
  width: 13vw;
  height: 2vw;
  margin-right: 2vw;
  font-size: 1vw;
  background-color: rgb(255, 255, 255);
  height: 2.5vw;
  display: flex;
  color: rgb(77, 77, 77);
  justify-content: space-around;
  align-items: center;
  font-size: 1.1vw;
  border-radius: 3px;
  cursor: pointer;
}
#filterDispatcher > input {
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  text-indent: 5%;
}

#filterLane {
  width: 7vw;
  height: 2vw;
  margin-right: 1vw;
  font-size: 1vw;
  background-color: rgb(255, 255, 255);
  height: 2.5vw;
  display: flex;
  color: rgb(77, 77, 77);
  justify-content: space-around;
  align-items: center;
  font-size: 1.1vw;
  border-radius: 3px;
  cursor: pointer;
}
#filterLane > input {
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  text-indent: 5%;
}
#searchCarrier {
  width: 25vw;
  height: 2vw;
  margin-right: 0vw;
  font-size: 1vw;
  background-color: rgb(91, 151, 85);
  height: 2.5vw;
  display: flex;
  color: rgb(255, 255, 255);
  justify-content: space-around;
  align-items: center;
  font-size: 1.1vw;
  border-radius: 3px;
  cursor: pointer;
}
#addLoad {
  border-radius: 3px;
  height: 2.5vw;
  width: 15vw;
  margin-left: 2vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.1vw;
  background-color: #4e5f8e;
  color: #fff;
  cursor: pointer;
}
#addOpenLoad {
  cursor: pointer;
  border-radius: 3px;
  height: 2.5vw;
  width: 15vw;
  margin-left: 2vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.1vw;
  background-color: #4e5f8e;
  color: #fff;
}
#addLoad:hover,
#addOpenLoad:hover {
  transition: 1s;
  background-color: #354268;
}
.firstIndex {
  background-color: #444 !important;
  color: #fff;
}
.secondIndex {
  background-color: #265682 !important;
  color: #fff;
}
.thirdIndex {
  background-color: #444 !important;
  color: #fff;
}
.firstIndex:hover {
  background-color: #101010 !important;
}
.secondIndex:hover {
  background-color: #193855 !important;
}
.thirdIndex:hover {
  background-color: #101010 !important;
}
.bigHomeDiv {
  position: absolute;
  left: 0;
  top: 5vw;
  width: 100vw;
  height: auto;
  min-height: 95vh;
  display: flex;
  background-color: #26568200;
}
.rightContent {
  width: 70%;
  margin-left: 24.5%;
}
.leftIndexDiv {
  width: 20%;
  margin-left: 5%;
  margin-top: 0vw;
  position: fixed;
}
.indexBtns {
  margin-top: 3vw;
}
.defaultIndex {
  margin-top: 2vw;
  font-weight: 600;
  color: #444;
  cursor: pointer;
  transition: 0.5s;
  font-size: 1vw;
}
.currentIndex {
  transition: 0.5s;
  margin-top: 2vw;
  font-size: 1.2vw;
  font-weight: 600;
  cursor: pointer;
  color: rgb(27, 124, 151);
}
.spaceForLogout {
  margin-top: 8vw;
}

/* End of Dash */

/* Current Load */

.bigCurrentDiv {
  margin-bottom: 5vw;
}
.actualLoad {
  background-color: rgb(255, 255, 255);
  width: 95%;
  height: 5vw;
  margin-top: 1vw;
  border-radius: 2px;
  box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.283);
  display: flex;
  justify-content: space-between;
  padding: 0 2vw;
  align-items: center;
  font-size: 1vw;
  cursor: pointer;
  transition: 0.5s;
}
.actualLoadQuoteStage2 {
  background-color: rgb(207, 242, 200);
  width: 95%;
  height: 5vw;
  margin-top: 1vw;
  border-radius: 2px;
  box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.283);
  display: flex;
  justify-content: space-between;
  padding: 0 2vw;
  align-items: center;
  font-size: 1vw;
  cursor: pointer;
  transition: 0.5s;
}
.actualCurrentStage3Load {
  background-color: rgb(255, 255, 255);
}
.actualCurrentStage4Load {
  background-color: rgb(255, 255, 255);
}
.actualCurrentLoad {
  background-color: rgb(255, 255, 255);
}
.actualCurrentLoad:hover {
  background-color: rgb(245, 245, 245);
  box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.283);
}
.actualOpenLoad:hover {
  box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.283);
}
.actualMasterLoad {
  background-color: rgb(210, 238, 255);
}
.actualMasterLoadPaperWork {
  background-color: rgb(210, 255, 218);
}
.titleActualLoadWrapperAuto {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.titleActualLoadWrapper {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.titleActualLoadInner {
  font-weight: 600;
  font-size: 0.9vw;
}
.titleActualLoadTag > span {
  font-size: 11px;
  background-color: #4271ff;
  border-radius: 4px;
  padding: 2px 20px;
  font-weight: 600;
  color: #fff;
}
.titleActualLoad {
  font-weight: 600;
  font-size: 0.9vw;
}
.titleOpen {
  width: 30%;
}
.firstAttribute,
.secondAttribute,
.secAttribute,
.thirdAttribute {
  font-size: 0.8vw;
}
.firstAttribute > span {
  font-weight: 600;
  font-size: 0.8vw;
}
.firstAttOpen {
  width: 30%;
  text-align: left;
}
.firstAttQuote {
  width: 18%;
  text-align: left;
}
.firstAttOpen2 {
  width: 50%;
  text-align: left;
}
.secAttribute {
  font-weight: 500;
}
.thirdAttribute > span {
  font-size: 0.8vw;
  font-weight: 600;
}
.dateCreatedQuote {
  font-size: 0.8vw;
  font-weight: 600;
  width: 10%;
}
.fourthOnOpen {
  display: flex;
}
.markBooked {
  margin-right: 0.5vw;
  color: #fff;
  font-weight: 600;
  background-color: #53ce7c;
  padding: 0.2vw 1vw;
  border-radius: 2px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.9vw;
}
.markRemove {
  margin-left: 0.5vw;
  color: #fff;
  font-weight: 600;
  background-color: #c24848;
  padding: 0.2vw 1vw;
  border-radius: 2px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.5s;
  font-size: 0.9vw;
}
.dupBtn {
  margin-left: 1vw;
  color: #fff;
  font-weight: 600;
  background-color: #537e75;
  padding: 0.2vw 1vw;
  border-radius: 2px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.5s;
  font-size: 0.9vw;
}
.markBooked:hover {
  transition: 0.5s;
  background-color: #46b373;
}
.markRemove:hover {
  transition: 0.5s;
  background-color: #a03737;
}
.dupBtn:hover {
  transition: 0.5s;
  background-color: #46665f;
}
.regCheck {
  padding-left: 5px;
}
.checkCircle {
  font-size: 1.3vw;
  color: rgb(30, 191, 16);
  cursor: pointer;
  transition: 0.2s;
}
.checkCircle:hover {
  color: rgb(38, 242, 20);
}
.checkCircleMaster {
  font-size: 1.3vw;
  color: rgb(30, 191, 16);
  cursor: pointer;
  transition: 0.2s;
}
.checkCircleMaster:hover {
  color: rgb(38, 242, 20);
}

/* End Current Load */

/* Carrier List */

.actualCarrier {
  padding: 0 2vw;
  font-size: 1vw;
  background-color: rgb(255, 255, 255);
  height: auto;
  width: 95%;
  margin-top: 1vw;
  border-radius: 4px;
  transition: 0.4s;
  border: 2px solid #737373;
}
.actualCarrier:hover {
  background-color: rgb(249, 248, 248);
}
.normalCarrier {
  border: 2px solid #737373;
}
.warning {
  border: 3px solid rgb(243, 175, 27);
}
.danger {
  border: 3px solid #e02a2a;
}
.titleSectionDivCarrier {
  display: flex;
  justify-content: space-between;
  margin-top: 1vw;
}
.openLoadsBookedIcon {
  padding-left: 0.5vw;
  font-size: 0.7vw;
}
.btns4Carrier {
  display: flex;
}
.editCarrierBtn {
  background-color: #2e2e2e;
  padding: 0.25vw 1.5vw;
  border-radius: 3px;
  color: rgb(255, 255, 255) !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  height: 2vw !important;
  margin-left: 1vw;
  cursor: pointer;
}
.loadsBooked {
  background-color: #248738;
  padding: 0.25vw 1vw;
  border-radius: 3px;
  color: rgb(255, 255, 255) !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  height: 2vw !important;
}
.loadsBooked:hover {
  background-color: #1d6a2d;
}
.listOfContactsDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 1vw;
  width: 75%;
  font-weight: 500;
  border-bottom: 1px solid #000;
}
.listOfPrefDiv {
  margin-top: 1vw;
  font-size: 0.8vw;
  display: flex;
  justify-content: space-between;
  width: 20%;
  font-weight: 500;
}
.carrierComments {
  margin: 2vw 2vw;
}
.carrierComments > h1 {
  font-size: 15px;
  margin: 0 0 0 -1vw;
  display: flex;
  align-items: center;
}
.carrierComments > h1 > i {
  font-size: 0.8vw;
  padding: 0.5vw 1vw;
  color: #333;
  border: 1px solid #969696;
  border-radius: 0.3vw;
  margin-left: 1vw;
  cursor: pointer;
}
.commentAuthor {
  margin-top: 1vw;
  margin-bottom: 0.2vw;
  font-weight: 500;
  font-size: 0.9vw;
  color: rgb(59, 98, 164);
  text-decoration: underline;
}
.commentContent {
  font-size: 0.9vw;
}

/* Carrier List */

/* Pop Ups */

.fixedPopUp {
  position: fixed;
  background-color: #00000041;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  justify-content: space-around;
}
.absPopUp {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: auto;
  z-index: 10;
  display: flex;
  justify-content: space-around;
}
.addCarrierPopUp {
  background-color: #fff;
  width: 50%;
  height: 50%;
  margin-top: 2%;
  border-radius: 6px;
  padding: 2vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}
.editCarrierPopUp {
  background-color: #fff;
  width: 50%;
  min-height: 20%;
  max-height: 40%;
  overflow-y: scroll;
  margin-top: 5%;
  border-radius: 6px;
  padding: 2vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.addOpenPopUp {
  background-color: #fff;
  width: 50%;
  height: 70%;
  margin-top: 2%;
  border-radius: 6px;
  padding: 2vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.singleLoadPopUp {
  background-color: #fff;
  width: 60%;
  height: 85%;
  margin-top: 1%;
  border-radius: 6px;
  padding: 2.5% 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.markBookedPopUp {
  background-color: #fff;
  width: 50%;
  height: 50%;
  margin-top: 2%;
  border-radius: 6px;
  padding: 2vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.carrierCommmentPopUp {
  background-color: #fff;
  width: 50%;
  height: 30%;
  margin-top: 2%;
  border-radius: 6px;
  padding: 2vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.customLabelPopUp {
  background-color: #fff;
  width: 400px;
  height: 200px;
  margin-top: 2%;
  border-radius: 6px;
  padding: 2vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.customLabelPopUp > div > h1 {
  margin: 0;
  padding-bottom: 20px;
  font-size: 18px;
}
.infoCustomLabel{
  font-size: 14px;
  margin-bottom: 10px;
}
.inputCustomLabel{
  font-size: 14px;
}
.inputCustomLabel > input{
  width: 200px;
  height: 24px;
}
.carrierPopUp {
  background-color: #fff;
  width: 50%;
  height: 55%;
  margin-top: 2%;
  border-radius: 6px;
  padding: 2vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.carrierPopUp > h1 {
  margin: 0;
  font-size: 1.4vw;
}
.carrierWarnPopUp {
  background-color: #fff;
  width: 50%;
  height: 20%;
  margin-top: 2%;
  border-radius: 6px;
  padding: 2vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.carrierWarnPopUp > h1 {
  margin: 0;
  font-size: 1.4vw;
}
.carrierWarnPopUp > p {
  margin: 0;
  font-size: 1.2vw;
}
.dayRateDiv {
  display: flex;
  justify-content: space-between;
}
.dayRate {
  width: 15%;
  height: 3vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 600;
}
.firstNum {
  border: 2px solid #de5b5b;
}
.firstNumFill {
  border: 2px solid #de5b5b;
  background-color: #c54545;
}
.secNum {
  border: 2px solid #eaa652;
}
.secNumFill {
  border: 2px solid #eaa652;
  background-color: #ea9631;
}
.thirdNum {
  border: 2px solid #e2f22f;
}
.thirdNumFill {
  border: 2px solid #e2f22f;
  background-color: #e6fa00;
}
.fourthNum {
  border: 2px solid #70f22f;
}
.fourthNumFill {
  border: 2px solid #70f22f;
  background-color: #9af55e;
}
.fifthNum {
  border: 2px solid #52ed04;
}
.fifthNumFill {
  border: 2px solid #52ed04;
  background-color: #09ff00;
}
.infoCarrier {
  font-size: 1vw;
  font-weight: 500;
  margin: 1vw 0;
}
.notesDivCarrier > h1 {
  font-size: 1.2vw;
}
.notesDivCarrier > textarea {
  font-size: 1vw;
  width: 100%;
  height: 4vw;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.arrowIcon {
  margin-top: 1.5vw;
  font-size: 1.4vw;
  color: #444;
}
.arrowIcon2 {
  padding: 0 1vw;
  font-size: 1.2vw;
  color: #444;
}
.inputRow1 {
  display: flex;
  justify-content: space-between;
}
.newInputRow1 {
  display: flex;
  justify-content: space-between;
}
.singleOpenLoadNone {
  display: none;
}
.dummy {
  background-color: #193855;
}
.inputRow1MarkBooked {
  display: flex;
  justify-content: space-between;
}
.inputRow2 {
  display: flex;
  justify-content: space-around;
}
.inputRow2MarkBooked {
  display: flex;
  justify-content: space-between;
}
.datePickerDiv {
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #f2f2f2;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-align: center;

  height: 1.8vw;
  border: 1.8px solid rgb(43, 90, 218);
  border-radius: 3px;
}
.react-date-picker__inputGroup > input {
  cursor: pointer !important;
}
.xxDatePicker {
  border: 0 !important;
}
.react-date-picker,
.react-date-picker--open,
.react-date-picker--enabled {
  width: 100%;
  height: 100%;
}
.react-date-picker__wrapper {
  border: 0 !important;
  width: 100%;
}
.react-calendar {
  border-radius: 6px;
}
.react-calendar__tile--active {
  background: #4dd071 !important;
  color: #444 !important;
}
.react-calendar__month-view__days__day--weekend {
  color: black !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}
.react-date-picker__inputGroup {
  text-align: center;
}
.react-calendar__tile--now {
  background: rgb(240, 240, 240) !important;
  color: rgb(50, 164, 50) !important;
}
.react-calendar__tile {
  font-weight: 600;
}
.inputRow3 {
  display: flex;
  justify-content: space-between;
}
.inputRow3MarkBooked {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2vw;
}
.inputRow4MarkBooked {
  display: flex;
  justify-content: space-around;
  padding-bottom: 2vw;
}
.inputRow4 {
  display: flex;
  justify-content: space-around;
}
.inputRow6 {
  display: flex;
  justify-content: flex-start;
  width: auto;
}
.scannedDivContainer {
  display: flex;
}
.scannedDivContainer > div {
  display: flex;
  flex-direction: column;
}
.scannedDivContainer > div > label {
  font-size: 0.9vw;
  font-weight: 600;
  color: #595959;
}
.scannedDivContainer > div > input {
  height: 28px;
  width: 150px;
  border: 0;
  border-radius: 0;
  text-indent: 5px;
  font-size: 13px;
}
.scannedDivContainer > p {
  color: #9f1414;
  padding-left: 8px;
  padding-top: 12px;
  font-size: 13px;
  font-weight: 500;
  width: 100px;
  cursor: pointer;
}
.scannedRow > p{
  color: #0036d8;
  padding: 5px 5px;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 500;
  width: 150px;
  cursor: pointer;
}
.newInputRowCarrier {
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.actualDispatchContainerCarrierPopUp {
  margin-top: 0vw;
  margin-bottom: 1vw;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.actualDispatchDivCarrierPopUp {
  font-size: 0.9vw;
  display: flex;
  font-weight: 500;
}
.leftSideOfDispatchDiv > p {
  margin: 0;
  padding-bottom: 1vw;
  font-size: 0.9vw;
  font-weight: 600;
}
.rightSideOfDispatchDiv > h6 {
  margin: 0.5vw 0 0 0;
  text-align: right;
  color: #9013ce;
  cursor: pointer;
}
.inputFlexDivDispatchers > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5vw;
}
.inputFlexDivDispatchers > div > label {
  width: 2vw;
  margin-right: 1vw;
  font-size: 0.9vw;
}
.inputFlexDivDispatchers > div > input {
  width: 10vw;
  margin-right: 1vw;
  font-size: 0.9vw;
}
.actualDispatchDivCarrierPopUp > div > i {
  font-size: 0.9vw;
  margin-left: 1vw;
  color: rgb(147, 44, 44);
  cursor: pointer;
}
.preferredRow {
  align-items: center !important;
}
.preferredLaneDivInput {
  width: 16% !important;
}
.preferredLaneDiv {
  font-size: 0.8vw;
}
.preferredTag {
  font-size: 1vw;
  font-weight: 500;
  color: #193855;
  padding-bottom: 2vw;
  width: 25% !important;
}
.addBtnPreferred {
  width: 4% !important;
  padding: 0.5vw;
  font-size: 0.8vw;
  font-weight: 600;
  cursor: pointer;
}
.inputRow6 > div > input {
  border: 0 !important;
  border-radius: 0 !important;
}
.inputRow > div {
  display: flex;
  flex-direction: column;
}

.inputRow > div > label {
  font-size: 0.9vw;
  font-weight: 600;
  color: #595959;
}
.inputRow > div > input {
  height: 28px;
  border: 1.8px solid rgb(108, 108, 108);
  border-radius: 3px;
  text-indent: 5px;
  font-size: 13px;
}
.inputRow > div > select {
  height: 28px;
  border: 1.8px solid rgb(108, 108, 108);
  border-radius: 3px;
  text-indent: 5%;
  cursor: pointer;
}
.inputRow3 > div > input {
  border: 1.8px solid rgb(62, 70, 145) !important;
  border-radius: 3px;
}
.inputRow3 > div > select {
  border: 1.8px solid rgb(62, 70, 145) !important;
  border-radius: 3px;
}
.inputRow3 > div > label {
  color: rgb(92, 96, 133) !important;
}
.inputRow3MarkBooked > div > input {
  border: 1.8px solid rgb(62, 70, 145) !important;
  border-radius: 3px;
}
.inputRow2MarkBooked > div > label {
  color: rgb(98, 162, 116) !important;
}
.inputRow2MarkBooked > div > input {
  border: 1.8px solid rgb(62, 145, 81) !important;
  border-radius: 3px;
}
.inputRow3MarkBooked > div > label {
  color: rgb(69, 73, 114) !important;
}
.inputRow4 > div > input {
  border: 1.8px solid rgb(70, 145, 62) !important;
  border-radius: 3px;
}
.inputRow4 > div > label {
  color: #457249 !important;
}
.inputRow5 > div > textarea {
  border: 1.8px solid rgb(59, 138, 147) !important;
  border-radius: 3px;
  font-size: 13px;
  font-family: "Poppins", Arial;
  height: 90px;
  margin-bottom: 10px;
}
.inputRow5 > div > label {
  color: #526f78 !important;
}
.newInputRowCarrier > div {
  width: 30%;
}
.inputRow1 > div {
  width: 30%;
}
.newInputRow1 > div {
  width: 16%;
}
.newInputRow1 > div:first-child {
  width: 20%;
}
.inputRow2 > div {
  width: 15%;
}
.inputRow2MarkBooked > div {
  width: 25%;
}
.inputRow3 > div {
  width: 17%;
}
.inputRow3MarkBooked > div {
  width: 25%;
}
.carrierInputDiv {
  width: 25%;
}
.carrierInputDiv2 {
  width: 10%;
  height: 100%;
}
.mcInputDiv {
  width: 25%;
}
.firmOrFlex {
  display: flex;
  flex-direction: row !important;
  width: 10%;
  justify-content: space-around;
  align-items: center;
}
.firm {
  border: 2px solid #6fea88;
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 2px;
  height: 50%;
}
.flex {
  border: 2px solid #7d7d7d;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 2px;
  height: 50%;
  font-size: 1vw;
  transition: 0.5s;
  color: rgb(148, 148, 148);
  cursor: pointer;
}
.flexOn {
  cursor: pointer;
  transition: 0.5s;
  border: 2px solid #248738;
  background-color: #248738;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 2px;
  height: 50%;
  font-size: 1vw;
  color: #fff;
}
.truckRateInput {
  display: flex;
  flex-direction: column;
}
.truckRateInput > input {
  border: 1.8px solid rgb(70, 145, 62) !important;
  border-radius: 3px;
  height: 1.8vw;
  text-indent: 5%;
}
.truckRateInput > div {
  border: 1.8px solid rgb(70, 145, 62) !important;
  border-radius: 3px;
  height: 1.8vw;
  margin-left: 2vw;
}
.truckRateInput > div > input {
  text-indent: 5%;
}
.truckRateInput > label {
  font-size: 0.9vw;
  font-weight: 600;
  color: #457249;
}
.truckRateInputBigDiv {
  display: flex !important;
  flex-direction: row !important;
}
.labelDivContainer {
  padding-bottom: 20px;
}
.labelsLabel {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}
.labelsLabel > svg {
  width: 15px;
  margin-right: 5px;
}
.labelDiv {
  display: flex;
  align-items: center;
}
.labelBtnDiv {
  width: 120px;
  height: 25px;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 4px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.labelBtnDiv:hover {
  opacity: 0.9;
}
.customLabelBtnDiv {
  width: auto;
  height: 25px;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 4px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 5px;
}
.customLabelBtnDiv:hover {
  opacity: 0.9;
}

/* BTN Row */

.btnRow {
  display: flex;
  justify-content: space-between;
  font-size: 1vw;
}
.btnRow2 {
  display: flex;
  justify-content: right;
}
.btnRowMarkBooked {
  padding-top: 2vw;
  display: flex;
  justify-content: space-between;
}
.btnRowFiller {
  width: 20%;
}
.btnRowFiller2 {
  width: 20%;
}
.cancelBtn {
  background-color: #484848;
  padding: 0.25vw 3vw;
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.304);
  cursor: pointer;
}
.inProgressBtn {
  background-color: #0036d8;
  padding: 0.25vw 2.3vw;
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.304);
  cursor: pointer;
}
.removeInProgressBtn {
  background-color: #484848;
  padding: 0.25vw 2.3vw;
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.304);
  cursor: pointer;
}
.revertBtn {
  background-color: #873535;
  padding: 0.25vw 3vw;
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.304);
  cursor: pointer;
}
.delBtn {
  background-color: #b12a2a;
  padding: 0.25vw 3vw;
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.304);
  cursor: pointer;
}
.postBtn {
  cursor: pointer;
  background-color: #6176a4;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.185);
  color: #fff;
  font-weight: 500;
  padding: 0.25vw 3vw;
  border-radius: 2px;
}
.copyMasterBtn {
  cursor: pointer;
  background-color: #6176a4;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.185);
  color: #fff;
  font-weight: 500;
  padding: 0.25vw 3vw;
  border-radius: 2px;
}
.saveBtn {
  cursor: pointer;
  background-color: #61a48e;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.185);
  color: #fff;
  font-weight: 500;
  padding: 0.25vw 3vw;
  border-radius: 2px;
}

/* End BTN Row */

.removeLoadPopUp {
  background-color: #fff;
  width: 50%;
  height: 20%;
  margin-top: 2%;
  border-radius: 6px;
  padding: 3vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.removeLoadPopUp > h1 {
  color: #444;
  font-size: 1.5vw;
  margin: 0;
}
.CalculatePopUp {
  background-color: rgb(255, 255, 255);
  width: 70vw;
  height: auto;
  min-height: 20vw;
  margin-top: 0;
  border-radius: 6px;
  padding: 1vw 2vw;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.229);
}
.lanePopUp {
  background-color: rgb(255, 255, 255);
  width: 60vw;
  height: 30vw;
  margin-top: 5vw;
  border-radius: 6px;
  padding: 1vw 2vw;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.229);
}
.singleSearchedLoad {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 1.1vw;
  padding: 0.4vw 0;
  border-bottom: 1px solid #333;
}
.singleSearchedLoad > div {
  width: 20%;
  text-align: center;
}
.singleSearchedLane {
  display: flex;
}
.singleSearchedLane > p {
  display: flex;
  margin: 0.3vw 0.2vw;
  font-size: 1vw;
  font-weight: 500;
}
.loadHistory {
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 4px;
  margin-top: 4vw;
}
.laneHistory {
  background-color: #d0dbe4;
  border-radius: 4px;
  margin-top: 4vw;
  display: flex;
  flex-wrap: wrap;
}
.cancelNPrintDiv {
  width: 98%;
  text-align: right;
  padding: 1vw 0 0 0;
  font-size: 1.5vw;
  color: #9a9a9a;
}
.cancelNPrintDiv > i {
  cursor: pointer;
}
.printDivSearchHours {
  cursor: pointer;
}
.searchDatesDiv {
  width: 45%;
  margin-left: 3%;
  height: 3vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datePickerDivSearchHours {
  border-radius: 4px;
  border: 2px solid #bfbfbf;
  width: 33%;
  height: 100%;
  color: rgb(0, 0, 0);
  font-size: 1.3vw;
  font-weight: 500;
  cursor: pointer;
}
.datePickerArrowSearchHours {
  font-size: 2vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: rgb(0, 0, 0);
}
.searchBtnDivSearchHours {
  padding-left: 1%;
  font-size: 1vw;
  margin: 0;
  display: flex;
  align-items: baseline;
  height: 0;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}
.searchstatesDiv {
  width: 75%;
  margin-left: 0%;
  height: 3vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.statePickerDivSearchHours > input {
  border-radius: 4px;
  border: 2px solid #8f2c2c;
  width: 70%;
  height: 100%;
  color: rgb(0, 0, 0);
  font-size: 1vw;
  font-weight: 500;
  cursor: pointer;
  padding: 0.5vw;
}
.statePickerArrowSearchHours {
  font-size: 2vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: rgb(0, 0, 0);
}
.bigTotalHoursSearchHoursDiv {
  margin-bottom: 5vw;
  margin-top: 3vw;
  width: 35%;
  height: 3vw;
  margin-left: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}
.totalHoursSearchHoursDiv {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  border: 2px solid #444;
  border-radius: 4px;
}
.innerTotalHoursSearchHoursDiv {
  width: 60%;
  display: flex;
  justify-content: space-between;
  height: auto;
  margin-left: 20%;
  font-size: 1.2vw;
  font-weight: 500;
}
.bigShiftHistoryDiv {
  height: auto;
  width: 70%;
  padding-bottom: 3vw;
  margin-left: 3%;
}
.singleShiftHistoryDiv {
  display: flex;
  width: 100%;
  border-bottom: 2px solid rgb(205, 205, 205);
  padding-bottom: 1vw;
  margin-top: 2vw;
  font-size: 1vw;
}
.singleShiftHistoryDiv > div {
  width: 30%;
}
.singleShiftHistoryDivUnexcused {
  display: flex;
  width: 100%;
  border-bottom: 2px solid rgb(205, 205, 205);
  background-color: rgb(255, 168, 168);
  padding-bottom: 1vw;
  padding-top: 1vw;
  margin-top: 2vw;
  font-size: 1vw;
}
.searchH1 {
  margin: 0;
  font-size: 1.4vw;
  padding-bottom: 1vw;
  color: rgb(0, 0, 0);
}
.singleShiftHistoryDivUnexcused > div {
  width: 30%;
}
.singleShiftHistoryDivExcused {
  display: flex;
  width: 100%;
  border-bottom: 2px solid rgb(205, 205, 205);
  background-color: rgb(191, 252, 183);
  padding-bottom: 1vw;
  padding-top: 1vw;
  margin-top: 2vw;
  font-size: 1vw;
}
.singleShiftHistoryDivExcused > div {
  width: 30%;
}
.h2NoShifts {
  font-size: 1vw;
  margin: 0 0 0 1vw;
}
#dateShiftHistoryDiv {
  font-weight: 600;
}
.filterLoadPopUp {
  background-color: #fff;
  width: 30%;
  height: 20vw;
  margin-top: 2%;
  border-radius: 6px;
  padding: 3vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.filterLoadPopUp > div > h1 {
  color: #444;
  font-size: 1.5vw;
  margin: 0;
}
.addFilterP {
  background-color: #193855;
  width: 8vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 1vw;
  cursor: pointer;
}
.filterSelectDiv {
  display: flex;
  flex-direction: column;
  width: 110vw;
}
.filterSelectDiv > select {
  height: 100%;
}
.filterInputRow {
  width: 50%;
  display: flex;
  justify-content: space-around;
}
.confirmDeliveryPopUp {
  background-color: #fff;
  width: 50%;
  height: 15%;
  margin-top: 2%;
  border-radius: 6px;
  padding: 2vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.confirmDeliveryPopUp > h1 {
  color: #444;
  font-size: 1.5vw;
  margin: 0;
}

/* End Pop Ups */

/* Staff */

.bigStaffDiv {
  display: flex;
  background-color: #19385500;
  flex-wrap: wrap;
  justify-content: left;
}

.singleGuest {
  width: 20vw;
  height: 8vw;
  background-color: rgb(203, 255, 243);
  padding: 1vw 1vw;
  border-radius: 5px;
  margin-top: 3vw;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.229);
  margin-left: 1vw;
}
.singleGuest > p {
  font-size: 0.8vw;
  margin: 0;
}
.singleStaff {
  width: 20vw;
  height: auto;
  background-color: #fff;
  padding: 1vw 1vw;
  border-radius: 5px;
  margin-top: 3vw;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.229);
  margin-left: 1vw;
}
.singleStaff > p {
  font-size: 0.8vw;
  margin: 0;
}
.nameStaff {
  font-size: 1vw !important;
  font-weight: 600;
}
.nameStaff > span {
  font-size: 11px !important;
  background-color: #0036d8;
  padding: 2px 15px;
  margin-left: 5px;
  border-radius: 4px;
  color: #fff;
}
.secondAttStaff {
  font-weight: 400;
  padding-top: 0.2vw;
}
.thirdAttStaff {
  font-weight: 500;
  background-color: #92ebb0;
  width: 90%;
  padding: 6px 5%;
  border-radius: 5px;
  margin-top: 10px !important;
}
.removeBtnStaff {
  font-weight: 400;
  padding-top: 1vw;
  text-decoration: underline;
  cursor: pointer;
}

/* End Staff */
.blubackCont {
  position: absolute;
  background-color: #444;
  height: auto;
  min-height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}
.blubackContXX {
    position: absolute;
    min-height: 100vh;
  background-color: #2c3336;
  height: auto;
  width: 100%;
}
.loginShape{
    position: absolute;
    width: 0;
  height: 0;
  border-top: 45vw solid #343434;
  border-right: 100vw solid transparent;
  z-index: -10;
}
.loginShape2{
    position: absolute;
    width: 0;
  height: 0;
  border-top: 45vw solid #3b6c7e;
  border-right: 100vw solid transparent;
  z-index: -10;
}
.loginShape6{
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10vw solid #93cfdc;
  border-right: 100vw solid transparent;
  z-index: -1;
}
.loginShape7{
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20vw solid #639e66;
  border-right: 100vw solid transparent;
  z-index: -2;
}
.loginShape8{
  position: absolute;
  width: 0;
  height: 0;
  border-top: 30vw solid #7fa5ae;
  border-right: 100vw solid transparent;
  z-index: -3;
}
.loginShape9{
  position: absolute;
  width: 0;
  height: 0;
  border-top: 37vw solid #6c7e85;
  border-right: 100vw solid transparent;
  z-index: -4;
}
.regShape{
    position: absolute;
    width: 0;
  height: 0;
  border-top: 2vw solid #d8d8d8;
  border-left: 100vw solid transparent;
  z-index: -1;
}
.authCont {
  margin-left: 35%;
  margin-right: 35%;
  background-color: transparent;
}
.authContXX {
  margin-left: 35%;
  margin-right: 35%;
  margin-top: 2%;
  background-color: transparent;
}
.authMiniCont {
  background-color: #fff;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.356);
}
.authMiniContXX {
  background-color: #fff;
  margin: 3px 20px;
  border-radius: 5px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.356);
}
.text-primary {
  color: #ccc;
}
.form-group {
  padding: 0px 15px;
  margin-left: 17%;
  margin-top: 0;
}
.form-groupEmail {
  padding: 8px 10px;
  margin-left: 18.5%;
  margin-top: 1%;
}
.form-group input {
  width: 75%;
  margin-top: 2px;
  height: 35px;
  border: 1px solid #c5c5c5;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
  cursor: text;
  font-size: 1vw;
  text-indent: 5%;
  color: rgb(85, 85, 85);
  font-weight: bold;
}
.form-group label {
  padding-right: 31%;
  font-size: 1vw;
  color: rgb(131, 131, 131);
}
.form-groupEmail label {
  padding-right: 31%;
  font-size: 1.8vh;
  color: rgb(131, 131, 131);
}
.fg1 {
  padding-top: 0%;
}
#greenFG4 input {
  background-color: rgb(54, 133, 67);
  cursor: pointer;
  width: 75%;
  margin-top: 5px;
  height: 35px;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
  font-size: 1.8vh;
  font-weight: bold;
  color: white;
  text-indent: -3%;
}
#redFG4 input {
  background-color: rgb(155, 64, 64);
  cursor: pointer;
  width: 75%;
  margin-top: 5px;
  height: 35px;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
  font-size: 1.8vh;
  font-weight: bold;
  color: white;
  text-indent: -3%;
}
#redFG4 > label, #greenFG4 > label{
    font-size: 0.9vw;
} 
.RegLogoDiv img {
  width: 20%;
  height: 10%;
  margin-top: 6%;
  margin-left: 40%;
}
.h1CreateAccount {
  margin-left: 5%;
  margin-bottom: 5%;
  font-size: 1.4vw;
  padding-top: 5%;
  font-weight: 600;
  color: #000000;
}
.h1CreateAccountXX {
  margin-left: 20%;
  font-size: 2.8vh;
  padding-top: 7%;
  font-weight: 500;
  color: #000000;
}
.registerFormBTN {
  margin-left: 21.5%;
  margin-top: 5%;
  width: 57%;
  padding: 10px 0;
  margin-bottom: 10%;
  font-size: 2vh;
  color: rgb(44, 44, 44);
  background-color: rgb(255, 255, 255);
  border: 1px solid #d7d8da;
  cursor: pointer;
  font-weight: 500;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
}
.loginFormBTN {
  font-weight: 500;
  margin-left: 21.5%;
  margin-top: 5%;
  width: 57%;
  padding: 10px 0;
  margin-bottom: 10%;
  font-size: 2vh;
  color: rgb(44, 44, 44);
  border: 1px solid #d7d8da;
  cursor: pointer;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
}
.bottomTextP {
  width: 100vw;
  text-align: center;
}
.bottomTextP p {
  font-size: 2vh;
  color: white;
  padding-bottom: 1.5%;
}
.bottomTextPXX {
  margin-left: 40%;
  margin-right: 37%;
}
.bottomTextPXX p {
  font-size: 2vh;
  color: white;
}
.hereTextP {
  color: #45a1e7;
}
.alert {
  padding: 0.7rem;
  opacity: 0.9;
  background: aqua;
  color: #333;
}

.alert-success {
  background: green;
  color: #fff;
}
.alert-danger {
  background: rgb(180, 51, 51);
  color: #fff;
}
.alert-mellow {
  background: rgb(231, 198, 50);
  color: white;
}
.displayNone {
  display: none;
}
.mobileNotSupportedImg{
  display: none;
}

/*
    Start media queries
/*



/* Mobile Not Supported - Queried */
@media screen and (max-width: 800px) {
  .mobileNotSupportedImg{
    display: block;
    position: fixed;
    width: 100vw;
    height: auto;
    min-height: 100vh;
    background-color: #ffffff;
    text-align: center;

  }
  .mobLogo > img{
    width: 40%;
    margin-top: 10vw;
  }
  .mobileNotSupportedImg > p {
    width: 50%;
    margin-left: 25%;
    font-size: 2.5vw;
    color: #444;
    font-weight: 500;
  }
  .mobileNotSupportedImg > p  > a{
    color: rgb(104, 145, 86);
  }
  .mobileNotSupportedImg > p  > span{
    color: rgb(152, 152, 152);
    font-weight: 400;
  }
}

/* End Mobile Not Supported - Queried */

@media screen and (max-width: 1000px) {
  .displayNoneOnMobile {
    display: none;
  }
  .authCont {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 3%;
    background-color: transparent;
  }
  .authContXX {
    margin-left: 35%;
    margin-right: 35%;
    margin-top: 2%;
    background-color: transparent;
  }
  .authMiniCont {
    background-color: #fff;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.15);
  }
  .authMiniContXX {
    background-color: #fff;
    margin: 3px 20px;
    border-radius: 5px;
    box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.15);
  }
  .text-primary {
    color: #ccc;
  }
  .form-group {
    padding: 8px 15px;
    margin-left: 17%;
    margin-top: 0;
  }
  .form-groupEmail {
    padding: 8px 10px;
    margin-left: 19%;
    margin-top: 1%;
  }
  #redFG4 > label, #greenFG4 > label{
    font-size: 2vw;
} 
  .form-group input {
    width: 75%;
    margin-top: 2px;
    height: 35px;
    border: 1px solid #c5c5c5;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
    cursor: text;
    font-size: 1.8vh;
    text-indent: 5%;
    color: rgb(85, 85, 85);
    font-weight: bold;
  }
  .form-group label {
    padding-right: 31%;
    font-size: 1.8vh;
    color: rgb(131, 131, 131);
  }
  .form-groupEmail label {
    padding-right: 31%;
    font-size: 1.8vh;
    color: rgb(131, 131, 131);
  }
  .fg1 {
    padding-top: 0%;
  }
  #greenFG4 input {
    background-color: rgb(54, 133, 67);
    cursor: pointer;
    width: 75%;
    margin-top: 5px;
    height: 35px;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
    font-size: 1.8vh;
    font-weight: bold;
    color: white;
    text-indent: -3%;
  }
  #redFG4 input {
    background-color: rgb(155, 64, 64);
    cursor: pointer;
    width: 75%;
    margin-top: 5px;
    height: 35px;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
    font-size: 1.8vh;
    font-weight: bold;
    color: white;
    text-indent: -3%;
  }
  .RegLogoDiv img {
    width: 20%;
    height: 10%;
    margin-top: 6%;
    margin-left: 40%;
  }
  .h1CreateAccount {
    margin-left: 20%;
    font-size: 2.7vh;
    padding-top: 7%;
    font-weight: 400;
    color: #000000;
  }
  .h1CreateAccountXX {
    margin-left: 20%;
    font-size: 2.8vh;
    padding-top: 7%;
    font-weight: 500;
    color: #000000;
  }
  .registerFormBTN {
    margin-top: 5%;
    padding: 10px 0;
    margin-bottom: 10%;
    font-size: 2vh;
    color: rgb(44, 44, 44);
    border: 1px solid #d7d8da;
    cursor: pointer;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
  }
  .loginFormBTN {
    margin-top: 5%;
    padding: 10px 0;
    margin-bottom: 10%;
    font-size: 2vh;
    color: rgb(44, 44, 44);
    border: 1px solid #d7d8da;
    cursor: pointer;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
  }
  
  .bottomTextP p {
    font-size: 2vh;
    color: white;
    padding-bottom: 1.5%;
  }
  .bottomTextPXX {
    margin-left: 40%;
    margin-right: 37%;
  }
  .bottomTextPXX p {
    font-size: 2vh;
    color: white;
  }
}

