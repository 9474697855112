.blubackCont {
  position: absolute;
  background-color: #444;
  height: auto;
  min-height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}
.blubackContXX {
    position: absolute;
    min-height: 100vh;
  background-color: #2c3336;
  height: auto;
  width: 100%;
}
.loginShape{
    position: absolute;
    width: 0;
  height: 0;
  border-top: 45vw solid #343434;
  border-right: 100vw solid transparent;
  z-index: -10;
}
.loginShape2{
    position: absolute;
    width: 0;
  height: 0;
  border-top: 45vw solid #3b6c7e;
  border-right: 100vw solid transparent;
  z-index: -10;
}
.loginShape6{
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10vw solid #93cfdc;
  border-right: 100vw solid transparent;
  z-index: -1;
}
.loginShape7{
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20vw solid #639e66;
  border-right: 100vw solid transparent;
  z-index: -2;
}
.loginShape8{
  position: absolute;
  width: 0;
  height: 0;
  border-top: 30vw solid #7fa5ae;
  border-right: 100vw solid transparent;
  z-index: -3;
}
.loginShape9{
  position: absolute;
  width: 0;
  height: 0;
  border-top: 37vw solid #6c7e85;
  border-right: 100vw solid transparent;
  z-index: -4;
}
.regShape{
    position: absolute;
    width: 0;
  height: 0;
  border-top: 2vw solid #d8d8d8;
  border-left: 100vw solid transparent;
  z-index: -1;
}
.authCont {
  margin-left: 35%;
  margin-right: 35%;
  background-color: transparent;
}
.authContXX {
  margin-left: 35%;
  margin-right: 35%;
  margin-top: 2%;
  background-color: transparent;
}
.authMiniCont {
  background-color: #fff;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.356);
}
.authMiniContXX {
  background-color: #fff;
  margin: 3px 20px;
  border-radius: 5px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.356);
}
.text-primary {
  color: #ccc;
}
.form-group {
  padding: 0px 15px;
  margin-left: 17%;
  margin-top: 0;
}
.form-groupEmail {
  padding: 8px 10px;
  margin-left: 18.5%;
  margin-top: 1%;
}
.form-group input {
  width: 75%;
  margin-top: 2px;
  height: 35px;
  border: 1px solid #c5c5c5;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
  cursor: text;
  font-size: 1vw;
  text-indent: 5%;
  color: rgb(85, 85, 85);
  font-weight: bold;
}
.form-group label {
  padding-right: 31%;
  font-size: 1vw;
  color: rgb(131, 131, 131);
}
.form-groupEmail label {
  padding-right: 31%;
  font-size: 1.8vh;
  color: rgb(131, 131, 131);
}
.fg1 {
  padding-top: 0%;
}
#greenFG4 input {
  background-color: rgb(54, 133, 67);
  cursor: pointer;
  width: 75%;
  margin-top: 5px;
  height: 35px;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
  font-size: 1.8vh;
  font-weight: bold;
  color: white;
  text-indent: -3%;
}
#redFG4 input {
  background-color: rgb(155, 64, 64);
  cursor: pointer;
  width: 75%;
  margin-top: 5px;
  height: 35px;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
  font-size: 1.8vh;
  font-weight: bold;
  color: white;
  text-indent: -3%;
}
#redFG4 > label, #greenFG4 > label{
    font-size: 0.9vw;
} 
.RegLogoDiv img {
  width: 20%;
  height: 10%;
  margin-top: 6%;
  margin-left: 40%;
}
.h1CreateAccount {
  margin-left: 5%;
  margin-bottom: 5%;
  font-size: 1.4vw;
  padding-top: 5%;
  font-weight: 600;
  color: #000000;
}
.h1CreateAccountXX {
  margin-left: 20%;
  font-size: 2.8vh;
  padding-top: 7%;
  font-weight: 500;
  color: #000000;
}
.registerFormBTN {
  margin-left: 21.5%;
  margin-top: 5%;
  width: 57%;
  padding: 10px 0;
  margin-bottom: 10%;
  font-size: 2vh;
  color: rgb(44, 44, 44);
  background-color: rgb(255, 255, 255);
  border: 1px solid #d7d8da;
  cursor: pointer;
  font-weight: 500;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
}
.loginFormBTN {
  font-weight: 500;
  margin-left: 21.5%;
  margin-top: 5%;
  width: 57%;
  padding: 10px 0;
  margin-bottom: 10%;
  font-size: 2vh;
  color: rgb(44, 44, 44);
  border: 1px solid #d7d8da;
  cursor: pointer;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
}
.bottomTextP {
  width: 100vw;
  text-align: center;
}
.bottomTextP p {
  font-size: 2vh;
  color: white;
  padding-bottom: 1.5%;
}
.bottomTextPXX {
  margin-left: 40%;
  margin-right: 37%;
}
.bottomTextPXX p {
  font-size: 2vh;
  color: white;
}
.hereTextP {
  color: #45a1e7;
}
.alert {
  padding: 0.7rem;
  opacity: 0.9;
  background: aqua;
  color: #333;
}

.alert-success {
  background: green;
  color: #fff;
}
.alert-danger {
  background: rgb(180, 51, 51);
  color: #fff;
}
.alert-mellow {
  background: rgb(231, 198, 50);
  color: white;
}
.displayNone {
  display: none;
}
.mobileNotSupportedImg{
  display: none;
}

/*
    Start media queries
/*



/* Mobile Not Supported - Queried */
@media screen and (max-width: 800px) {
  .mobileNotSupportedImg{
    display: block;
    position: fixed;
    width: 100vw;
    height: auto;
    min-height: 100vh;
    background-color: #ffffff;
    text-align: center;

  }
  .mobLogo > img{
    width: 40%;
    margin-top: 10vw;
  }
  .mobileNotSupportedImg > p {
    width: 50%;
    margin-left: 25%;
    font-size: 2.5vw;
    color: #444;
    font-weight: 500;
  }
  .mobileNotSupportedImg > p  > a{
    color: rgb(104, 145, 86);
  }
  .mobileNotSupportedImg > p  > span{
    color: rgb(152, 152, 152);
    font-weight: 400;
  }
}

/* End Mobile Not Supported - Queried */

@media screen and (max-width: 1000px) {
  .displayNoneOnMobile {
    display: none;
  }
  .authCont {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 3%;
    background-color: transparent;
  }
  .authContXX {
    margin-left: 35%;
    margin-right: 35%;
    margin-top: 2%;
    background-color: transparent;
  }
  .authMiniCont {
    background-color: #fff;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.15);
  }
  .authMiniContXX {
    background-color: #fff;
    margin: 3px 20px;
    border-radius: 5px;
    box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.15);
  }
  .text-primary {
    color: #ccc;
  }
  .form-group {
    padding: 8px 15px;
    margin-left: 17%;
    margin-top: 0;
  }
  .form-groupEmail {
    padding: 8px 10px;
    margin-left: 19%;
    margin-top: 1%;
  }
  #redFG4 > label, #greenFG4 > label{
    font-size: 2vw;
} 
  .form-group input {
    width: 75%;
    margin-top: 2px;
    height: 35px;
    border: 1px solid #c5c5c5;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
    cursor: text;
    font-size: 1.8vh;
    text-indent: 5%;
    color: rgb(85, 85, 85);
    font-weight: bold;
  }
  .form-group label {
    padding-right: 31%;
    font-size: 1.8vh;
    color: rgb(131, 131, 131);
  }
  .form-groupEmail label {
    padding-right: 31%;
    font-size: 1.8vh;
    color: rgb(131, 131, 131);
  }
  .fg1 {
    padding-top: 0%;
  }
  #greenFG4 input {
    background-color: rgb(54, 133, 67);
    cursor: pointer;
    width: 75%;
    margin-top: 5px;
    height: 35px;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
    font-size: 1.8vh;
    font-weight: bold;
    color: white;
    text-indent: -3%;
  }
  #redFG4 input {
    background-color: rgb(155, 64, 64);
    cursor: pointer;
    width: 75%;
    margin-top: 5px;
    height: 35px;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
    font-size: 1.8vh;
    font-weight: bold;
    color: white;
    text-indent: -3%;
  }
  .RegLogoDiv img {
    width: 20%;
    height: 10%;
    margin-top: 6%;
    margin-left: 40%;
  }
  .h1CreateAccount {
    margin-left: 20%;
    font-size: 2.7vh;
    padding-top: 7%;
    font-weight: 400;
    color: #000000;
  }
  .h1CreateAccountXX {
    margin-left: 20%;
    font-size: 2.8vh;
    padding-top: 7%;
    font-weight: 500;
    color: #000000;
  }
  .registerFormBTN {
    margin-top: 5%;
    padding: 10px 0;
    margin-bottom: 10%;
    font-size: 2vh;
    color: rgb(44, 44, 44);
    border: 1px solid #d7d8da;
    cursor: pointer;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
  }
  .loginFormBTN {
    margin-top: 5%;
    padding: 10px 0;
    margin-bottom: 10%;
    font-size: 2vh;
    color: rgb(44, 44, 44);
    border: 1px solid #d7d8da;
    cursor: pointer;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
  }
  
  .bottomTextP p {
    font-size: 2vh;
    color: white;
    padding-bottom: 1.5%;
  }
  .bottomTextPXX {
    margin-left: 40%;
    margin-right: 37%;
  }
  .bottomTextPXX p {
    font-size: 2vh;
    color: white;
  }
}
